<template>
  <div class="tile is-parent">
    <article
      id="container"
      class="tile is-child box detail-page-tile">
      <div class="pnet-codemirror">
        <codemirror
          v-model="code"
          :options="options"
          :width="editorWidth" />
      </div>
    </article>
  </div>
</template>

<script>
import { codemirror } from '@/components/VueCodemirror'
// language js
import 'codemirror/mode/xml/xml.js'

export default {
  name: 'PnetMessageDetail',
  components: {
    codemirror
  },
  props: {
    value: null,
    options: null
  },
  data() {
    return {
      code: '',
      editorWidth: 400
    }
  },
  computed: {},
  watch: {
    code: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.code = this.value
    // this.editorWidth = document.getElementById('container').clientWidth - 40
    // this.$nextTick(function() {
    //   window.addEventListener('resize', this.resizeEditor)
    // })
  },
  methods: {
    onCmReady(cm) {
      console.log('the editor is readied!', cm)
    },
    onCmFocus(cm) {
      console.log('the editor is focus!', cm)
    },
    onCmCodeChange(newCode) {
      console.log('this is new code', newCode)
      this.code = newCode
    }
    // resizeEditor() {
    //   this.editorWidth = document.getElementById('container').clientWidth - 40
    // }
  }
}
</script>

<style lang="scss">
.pnet-codemirror {
  // Height minus header and menu
  height: calc(100vh - 170px);
  width: 100%;
}
</style>
