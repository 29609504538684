<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="entity !== null"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <pnet-message-view-side-menu
          v-if="options"
          v-model="options"
          :entity-name="messageSummary.regoNo" />
      </div>
      <div class="column">
        <pnet-message-detail
          v-if="options && showEditor && entity"
          v-model="entity.messageData"
          :options="options" />
      </div>
    </div>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal
      :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import PnetMessageViewSideMenu from './PnetMessageViewSideMenu'
import PnetMessageDetail from './PnetMessageDetail'
import PnetMessageService from './PnetMessageService'
import PnetMessageRoutes from './route-types'
import StoreMixin from './storeMixin'
import { UnsavedModal, SaveConflictModal } from '@/components/BulmaModal'
import { AppHeaderButtonTypes } from '@/enums'
import HttpStatus from '@/components/http-status'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PnetMessageView',
  components: {
    AppDetailHeader,
    PnetMessageViewSideMenu,
    PnetMessageDetail,
    UnsavedModal,
    SaveConflictModal
  },
  mixins: [StoreMixin],
  props: {
    messageId: String
  },
  data() {
    return {
      entity: null,
      showEditor: true,
      options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        line: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        lineSeparator: '\n',
        lineWrapping: true,
        mode: 'application/xml',
        theme: 'default'
      },
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null
    }
  },
  computed: {},
  watch: {
    'options.theme': {
      handler: function(newVal, oldVal) {
        this.showEditor = false
        this.$nextTick(function() {
          this.showEditor = true
        })
      },
      deep: true
    }
  },
  created() {
    this.getEntity()
    if (this.$userInfo.codemirrorTheme) {
      this.options.theme = this.$userInfo.codemirrorTheme
    }
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      this.$showSpinner()
      const id = this.$route.params.messageId
      if (reset) {
        this.clearSnapshots(id)
      }
      if (!this.currentSnapshot) {
        // this.entity = await PnetMessageService.getEntity(id)
        await this.getStoreItem(id)
      }
      this.entity = _cloneDeep(this.currentSnapshot)
      this.$hideSpinner()
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else {
          this.$router.push({
            name: PnetMessageRoutes.PnetMessageListView.name
          })
        }
      }
    },
    async save() {
      let responseStatus
      const title = 'Pnet Message'
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.snapshotDiff) {
          response = await PnetMessageService.putEntity(this.entity, this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }

        if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, 'Save successful')
        }
        responseStatus = true
      } catch (e) {
        this.$notification.openMessageXhrError('PNET Message', e)
        responseStatus = false
      } finally {
        this.$hideSpinner()
      }
      return responseStatus
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    this.clearSnapshots(this.entity.messageId)
    next()
  }
}
</script>
